export default function autoGenerateIndicators() {
  // Auto-generate carousel indicator html
  const bootCarousel = $(".carousel");
  bootCarousel.append(`<ol class="carousel-indicators"></ol>`);
  const indicators = $(".carousel-indicators");
  bootCarousel.find(".carousel-inner").children(".carousel-item").each(function(index) {
    (index === 0) ?
      indicators.append(`<li data-bs-target="#carouselCarros" data-bs-slide-to="${index}" class='active'></li>`) :
      indicators.append(`<li data-bs-target="#carouselCarros" data-bs-slide-to="${index}"></li>`);
  });
}