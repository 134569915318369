import i1 from "./1.jpeg"
import i2 from "./2.jpeg"
import i3 from "./3.jpeg"
import i4 from "./4.jpeg"
import i5 from "./5.jpeg"
import i6 from "./6.jpeg"
import i7 from "./7.jpeg"

export default {
  title: "Toyota Bandeirante 2",
  text: "Restauração completa",
  imgs: [i1, i2, i3, i4, i5 , i6, i7]
}